import request from '../../utils/request'
import { GETORGALLLIST, GETORGLIST, GETORGTREELIST } from '../constants'
/**
 * 获取树形数据
 * @returns
 */
export const getOrgTreeList = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/org/tree/', {
      params,
      isLoading,
    })
    const init = (arr) => {
      arr = arr.map((item) => {
        if (item.children) {
          item.children = init(item.children)
        }
        item.title = item.org_name || '全部'
        item.value = item.org_code || '全部'
        item.key = item.org_id || '全部'
        return item
      })
      return arr
    }
    let list = [res.data.data]
    let arr = init(list)
    dispatch({
      type: GETORGTREELIST,
      payload: arr,
    })
    return res.data.data.children
  }
}

/**
 * 获取列表数据
 * @param {*} params
 * @returns
 */
export const getOrgList = (params) => {
  // console.log(params)
  return async (dispatch) => {
    const res = await request.get('sys/org/', {
      params,
    })
    // console.log(res.data.data)
    dispatch({
      type: GETORGLIST,
      payload: res.data.data,
    })
  }
}
export const getOrgAllList = (params) => {
  // console.log(params)
  return async (dispatch) => {
    const res = await request.get('sys/org/', {
      params,
    })
    console.log(res.data.data)
    dispatch({
      type: GETORGALLLIST,
      payload: res.data.data,
    })
  }
}

/**
 * 新增部门
 * @param {*} values
 * @returns
 */
export const addOrg = (values) => {
  return async (dispatch) => {
    await request.post('sys/org/', values)
  }
}

/**
 * 修改部门
 * @param {*} values
 * @returns
 */
export const editOrgs = (values) => {
  return async (dispatch) => {
    await request.put(`sys/org/${values.id}/`, values)
  }
}

/**
 * 删除部门
 * @param {*} id
 * @returns
 */
export const delOrgs = (id) => {
  return async (dispatch) => {
    await request.delete(`sys/org/${id}/`)
  }
}

/**
 * 移动部门
 * @param {*} values
 * @returns
 */
export const moveOrgs = (values) => {
  return async (dispatch) => {
    await request.put('sys/org/move/', values)
  }
}

export const getTrigger = (params, isLoading) => {
  return async (dispatch) => {
    return await request.get('sys/org/check/', {
      params,
      isLoading,
    })
  }
}
