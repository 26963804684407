import { GETORGALLLIST, GETORGLIST, GETORGTREELIST } from '../constants'

const init = {
  orgTreeList: [],
  orgTreeListCope: [],
  orgList: [],
  orgAllList: [],
  total: 0,
  orgInfos: {},
}
export default function getOrgTreeList(state = init, action) {
  if (action.type === GETORGTREELIST) {
    return {
      ...state,
      orgTreeList: action.payload,
      orgTreeListCope: action.payload[0]?.children || [],
    }
  }
  if (action.type === GETORGLIST) {
    return {
      ...state,
      orgList: action.payload.data,
      total: action.payload.total,
    }
  }
  if (action.type === GETORGALLLIST) {
    return {
      ...state,
      orgAllList: action.payload,
    }
  }
  // if(action.type)
  return state
}
