import Cookie from 'js-cookie'

const Token = 'token'
const SuperGroup = 'super_group'
const Company = 'company'
/**
 * 存储token
 * @param {*} token
 */
export function setToken(token) {
  Cookie.set(
    Token,
    token,
    { expires: new Date().getTime() + 23 * 60 * 60 * 1000 },
    { HttpOnly: true }
  )
}
export function setSuperGroup(super_group) {
  Cookie.set(SuperGroup, super_group, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}
export function setCompany(company) {
  Cookie.set(Company, company, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}

/**
 * 获取token
 * @returns
 */
export function getToken() {
  return Cookie.get(Token)
}
export function getSuperGroup() {
  return Cookie.get(SuperGroup)
}
export function getCompany() {
  return Cookie.get(Company)
}

/**
 * 移除token
 * @returns
 */
export function removeToken() {
  return Cookie.remove(Token)
}
export function removeSuperGroup() {
  return Cookie.remove(SuperGroup)
}
export function removeCompany() {
  return Cookie.remove(Company)
}

/**
 * 判断是否有token
 * @returns
 */
export function hasToken() {
  return !!getToken()
}
