import React, {
  useEffect,
  useRef,
  useContext,
  useImperativeHandle,
  forwardRef,
} from 'react'
import {
  Card,
  Form,
  Button,
  Modal,
  Select,
  Space,
  Radio,
  Table,
  Drawer,
  ConfigProvider,
  Breadcrumb,
  Input,
  message,
  Tooltip,
} from 'antd'
import { EditableProTable } from '@ant-design/pro-table'

import zhCH from 'antd/lib/locale/zh_CN'
import { getCompany, getSuperGroup } from '../../../../utils/cookie'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompLists } from '../../../../store/actions/comp'
import styles from './index.module.scss'
import {
  addDictionary,
  delDictionary,
  editDictionary,
  getDicItemTrigger,
  getDictionaryItem,
  getDictionaryList,
  getTrigger,
} from '../../../../store/actions/dictionary'
import { useSize, useGetState } from 'ahooks'
import MyButton from '../../../../components/MyButton'
import { MyIcon } from '../../../../routers'
import ErrorList from 'antd/lib/form/ErrorList'

let dataTrigger = []
const EditableContext = React.createContext(null)
const TableContext = React.createContext(null)

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  // console.log(form)
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  // console.log(record)
  const [editing, setEditing] = useState(false)
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  const setErrorList = useContext(TableContext)

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async () => {
    try {
      const values = await form.validateFields([dataIndex])
      // console.log(values)
      setErrorList((prev) => {
        let arr = [...prev]
        let index = arr.indexOf(dataIndex + record.dic_item_id)
        if (index !== -1) {
          arr.splice(index, 1)
        }
        return arr
      })
      toggleEdit(false)
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
      setErrorList((prev) => {
        let index = prev.indexOf(dataIndex + record.dic_item_id)
        if (index === -1) {
          prev.push(dataIndex + record.dic_item_id)
        }
        return [...prev]
      })
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          padding: 0,
          width: 140,
          // float: 'left',
        }}
        name={dataIndex}
        rules={[
          dataIndex === 'dic_item_code' && {
            validator(_, value) {
              console.log(value, dataTrigger)
              if (value) {
                if (record.dic_item_code === value) {
                  return Promise.resolve()
                }
                const arr = dataTrigger.find((item, index) => {
                  console.log(item, index)
                  return item.dic_item_code === value
                })
                console.log(arr, value)
                if (arr) {
                  return Promise.reject('编码已存在')
                }
                return Promise.resolve()
              } else if (value === '') {
                return Promise.reject('编码不能为空')
              }
            },
          },
          dataIndex === 'dic_item_name' && {
            validator(_, value) {
              // console.log(value, dataTrigger)
              if (value) {
                if (record.dic_item_name === value) {
                  return Promise.resolve()
                }
                const arr = dataTrigger.find((item) => {
                  // console.log(item)
                  return item.dic_item_name === value
                })
                if (arr) {
                  return Promise.reject('名称已存在')
                }
                return Promise.resolve()
              } else if (value === '') {
                return Promise.reject('名称不能为空')
              }
            },
          },
        ]}
      >
        {dataIndex === 'dic_item_desc' ? (
          <Input
            maxLength={100}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        ) : (
          <Input
            maxLength={50}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        id={dataIndex + record.dic_item_id}
        style={{
          paddingRight: 24,
          minHeight: 22,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

let EditableTable = (props, ref) => {
  // console.log(props)
  const [columns, setColumns] = useState([
    {
      title: '序号',
      width: '8%',
      render: (text, record, index) => `${index + 1}`,
    },
    {
      title: '编码',
      dataIndex: 'dic_item_code',
      editable: true,
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '名称',
      dataIndex: 'dic_item_name',
      width: '30%',
      editable: true,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '描述',
      dataIndex: 'dic_item_desc',
      editable: true,
      width: '30%',

      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip
          overlayStyle={{ maxWidth: 560 }}
          placement="leftTop"
          title={text}
        >
          {text}
        </Tooltip>
      ),
    },
    {
      title: '',
      width: '8%',

      dataIndex: 'operation',
      render: (_, record) => (
        <MyIcon
          type="icon-a-jian1"
          onClick={() => handleDelete(record)}
        ></MyIcon>
      ),
    },
  ])
  const [dataSource, setDataSource, getDataSource] = useGetState(props.value)
  useEffect(() => {
    dataTrigger = dataSource
  }, [dataSource])
  const [count, setCount] = useState(1)
  const setErrorList = useContext(TableContext)

  const handleDelete = ({ dic_item_id }) => {
    console.log(getDataSource(), dic_item_id)
    const _dataSource = [...getDataSource()]
    // console.log(_dataSource.filter((item) => item.dic_item_id !== dic_item_id))
    setDataSource(
      _dataSource.filter((item) => item.dic_item_id !== dic_item_id)
    )
    setErrorList((prev) => {
      let arr = [...prev]
      let index = arr.indexOf('dic_item_code' + dic_item_id)
      if (index !== -1) {
        arr.splice(index, 1)
      }

      let index2 = arr.indexOf('dic_item_name' + dic_item_id)

      if (index2 !== -1) {
        arr.splice(index2, 1)
      }
      return arr
    })
  }
  const handleAdd = () => {
    const newData = {
      key: count,
      dic_item_id: count,
      dic_item_code: '',
      dic_item_name: '',
      dic_item_desc: '',
    }
    setCount(count + 1)
    setDataSource([...dataSource, newData])
  }
  const handleSave = (row) => {
    const newData = [...dataSource]
    const index = newData.findIndex(
      (item) => row.dic_item_id === item.dic_item_id
    )
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...row })
    setDataSource(newData)
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }
  const _columns = columns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    }
  })

  useImperativeHandle(ref, () => ({
    dataSource,
  }))

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <MyButton
          onClick={handleAdd}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          新增一行
        </MyButton>
      </div>
      <Table
        rowKey="dic_item_id"
        size="small"
        components={components}
        rowClassName={() => 'editable-row'}
        dataSource={dataSource}
        columns={_columns}
        pagination={false}
      />
    </div>
  )
}
// 暴露出
EditableTable = forwardRef(EditableTable)

export default function DataDictionary() {
  // 规则校验
  const rulesObj = useRef({
    co_id: [
      {
        required: true,
        message: '企业/产品名称不能为空',
      },
    ],
    dic_name: [
      {
        required: true,
        message: '数据字典名称不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
    dic_code: [
      {
        required: true,
        message: '数据字典编码不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
      {
        validator(_, value) {
          // console.log(getDicInfo())
          return dispatch(
            getTrigger(
              {
                pk: getDicInfo().dic_id ? getDicInfo().dic_id : null,
                co_id: getDicInfo().co_id ? getDicInfo().co_id : null,
                field: 'code',
                value,
              },
              false
            )
          ).then(({ data }) => {
            if (!data.data) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('数据字典编码已存在'))
          })
        },
        validateTrigger: 'onBlur',
      },
    ],
    dic_desc: [
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
  })

  const [visible, setVisible] = useState(false)
  const [watch, setWatch] = useState(false)
  const [delDicModal, setDelDicModal] = useState(false)
  const [dicInfo, setDicInfo, getDicInfo] = useGetState({})
  const [coId, setCoId, getCoId] = useGetState('')

  const dispatch = useDispatch()
  const formRef = useRef(null)
  const tableRef = useRef(null)
  const size = useSize(tableRef)
  // console.log(size)
  //获取企业/产品列表
  useEffect(() => {
    getSuperGroup() === 'true' &&
      dispatch(
        getCompLists({
          is_page: true,
        })
      )
  }, [dispatch])

  // 数据回显
  useEffect(() => {
    setTimeout(() => {
      formRef.current?.setFieldsValue({ ...dicInfo, co_id: dicInfo.co_id })
    }, 0)
  }, [dicInfo])

  // 获取数据字典数据
  useEffect(() => {
    dispatch(getDictionaryList())
  }, [dispatch])

  // 获取数据字典条目
  useEffect(() => {
    dispatch(getDictionaryItem())
  }, [dispatch])

  const { compListAll } = useSelector((state) => state.comp)
  const { dictionaryList, total } = useSelector((state) => state.dictionary)
  const paramsRef = useRef({
    page: 1,
    page_size: 10,
  })
  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])
  const columns =
    getSuperGroup() === 'true'
      ? [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '企业/产品',
            dataIndex: 'co_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '数据字典编码',
            dataIndex: 'dic_code',
            width: 140,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '数据字典名称',
            dataIndex: 'dic_name',
            width: 140,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '描述',
            dataIndex: 'dic_desc',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip
                overlayStyle={{ maxWidth: 560 }}
                placement="leftTop"
                title={text}
              >
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建人',
            dataIndex: 'created_by_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'dic',
            width: 150,
            fixed: 'right',
            render(dic) {
              return (
                <Space>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => {
                      showWatch(dic)
                    }}
                  >
                    查看
                  </Button>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => {
                      showEdit(dic)
                    }}
                  >
                    修改
                  </Button>
                  <Button
                    type="link"
                    className="del"
                    onClick={() => {
                      showDel(dic)
                    }}
                  >
                    删除
                  </Button>
                </Space>
              )
            },
          },
        ]
      : [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '数据字典编码',
            dataIndex: 'dic_code',
            width: 140,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '数据字典名称',
            dataIndex: 'dic_name',
            width: 140,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '描述',
            dataIndex: 'dic_desc',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip
                overlayStyle={{ maxWidth: 560 }}
                placement="leftTop"
                title={text}
              >
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建人',
            dataIndex: 'created_by',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'dic',
            width: 150,
            render(dic) {
              return (
                <Space>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => {
                      showWatch(dic)
                    }}
                  >
                    查看
                  </Button>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => {
                      showEdit(dic)
                    }}
                  >
                    修改
                  </Button>
                  <Button
                    type="link"
                    className="del"
                    onClick={() => {
                      showDel(dic)
                    }}
                  >
                    删除
                  </Button>
                </Space>
              )
            },
          },
        ]

  const watchColumns = [
    {
      title: '编码',
      dataIndex: 'dic_item_code',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '名称',
      dataIndex: 'dic_item_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '描述',
      dataIndex: 'dic_item_desc',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip
          overlayStyle={{ maxWidth: 560 }}
          placement="leftTop"
          title={text}
        >
          {text}
        </Tooltip>
      ),
    },
  ]
  // const [dataSource, setDataSource] = useState([])

  // 新增弹窗
  const showVisible = () => {
    setDicInfo({})
    setVisible(true)
  }
  //修改弹窗
  const showEdit = (dic) => {
    console.log(dic)
    dic = JSON.parse(JSON.stringify(dic))
    dic.dic_items = dic.dic_items.map((item) => {
      return {
        ...item,
        id: item.dic_item_id,
      }
    })
    setDicInfo(dic)
    setErrorList([])

    setVisible(true)
  }
  //删除弹窗
  const showDel = (dic) => {
    setDicInfo(dic)
    setDelDicModal(true)
  }

  //查看弹窗
  const showWatch = (dic) => {
    setDicInfo(dic)
    setWatch(true)
  }

  // 删除字典
  const delDic = async (id) => {
    await dispatch(delDictionary(id))
    setDelDicModal(false)
    message.success('删除数据字典成功')
    paramsRef.current.page = 1
    onReset()
    await dispatch(getDictionaryList())
  }

  // 分页处理
  const onChange = (page, pageSize) => {
    console.log(page, pageSize)
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    dispatch(getDictionaryList(paramsRef.current))
  }

  const paginations = {
    defaultCurrent: 1,
    current: paramsRef.current.page,
    defaultPageSize: 10,
    total: total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => {
      return `共${total}条`
    },
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
  }

  // 新增字典
  const onFinish = async (values) => {
    console.log(values)
    values.dic_items = tabRef.current.dataSource
    // console.log(tabRef)

    // const arr = tabRef.current.dataSource.filter(
    //   (item) => item.dic_item_code !== '' && item.dic_item_name !== ''
    // )
    // console.log(arr)

    // const { dic_items } = values
    // console.log(dic_items)

    // const items = arr.map((item) => {
    //   return {
    //     dic_item_code: item.dic_item_code,
    //     dic_item_name: item.dic_item_name,
    //     dic_item_desc: item.dic_item_desc,
    //   }
    // })
    if (errorList.length > 0) {
      return false
    }
    if (dicInfo.dic_id) {
      const arr = values.dic_items.map((item) => {
        return {
          dic_item_code: item.dic_item_code,
          dic_item_name: item.dic_item_name,
          dic_item_desc: item.dic_item_desc,
          items_id: item.dic_item_id,
        }
      })
      const items = arr.filter((item) => {
        return (
          (item.dic_item_code === '' && item.dic_item_name !== '') ||
          (item.dic_item_code !== '' && item.dic_item_name === '') ||
          (item.dic_item_desc !== '' &&
            item.dic_item_name === '' &&
            item.dic_item_code === '')
        )
      })
      console.log(items)
      if (items.length > 0) {
        items.forEach((item) => {
          let dom1 = null
          let dom2 = null
          if (item.dic_item_code === '') {
            dom1 = document.documentElement.querySelector(
              `#${'dic_item_code' + item.items_id}`
            )
          }
          if (item.dic_item_name === '') {
            dom2 = document.documentElement.querySelector(
              `#${'dic_item_name' + item.items_id}`
            )
          }
          if (dom1) {
            dom1.click()
          }
          if (dom2) {
            dom2.click()
          }
        })
        document.querySelector('.default').focus()
        return
      } else {
        console.log(arr)
        const newArr = arr.map((item) => {
          return {
            dic_item_code: item.dic_item_code,
            dic_item_name: item.dic_item_name,
            dic_item_desc: item.dic_item_desc,
          }
        })
        await dispatch(
          editDictionary({
            ...values,
            id: dicInfo.dic_id,
            dic_items: newArr.filter(
              (item) => item.dic_item_code !== '' && item.dic_item_name !== ''
            ),
          })
        )
      }
    } else {
      const arr = values.dic_items.map((item) => {
        return {
          dic_item_code: item.dic_item_code,
          dic_item_name: item.dic_item_name,
          dic_item_desc: item.dic_item_desc,
          items_id: item.dic_item_id,
        }
      })
      const items = arr.filter((item) => {
        return (
          (item.dic_item_code === '' && item.dic_item_name !== '') ||
          (item.dic_item_code !== '' && item.dic_item_name === '') ||
          (item.dic_item_desc !== '' &&
            item.dic_item_name === '' &&
            item.dic_item_code === '')
        )
      })
      if (items.length > 0) {
        items.forEach((item) => {
          let dom1 = null
          let dom2 = null
          if (item.dic_item_code === '') {
            dom1 = document.documentElement.querySelector(
              `#${'dic_item_code' + item.items_id}`
            )
          }
          if (item.dic_item_name === '') {
            dom2 = document.documentElement.querySelector(
              `#${'dic_item_name' + item.items_id}`
            )
          }
          if (dom1) {
            dom1.click()
          }
          if (dom2) {
            dom2.click()
          }
        })

        document.querySelector('.default').focus()
        return
      } else {
        const newArr = arr.map((item) => {
          return {
            dic_item_code: item.dic_item_code,
            dic_item_name: item.dic_item_name,
            dic_item_desc: item.dic_item_desc,
          }
        })
        await dispatch(
          addDictionary({
            ...values,
            dic_items: newArr.filter(
              (item) => item.dic_item_code !== '' && item.dic_item_name !== ''
            ),
          })
        )
      }
    }
    message.success(dicInfo.dic_id ? '修改字典成功' : '新增字典成功')
    setVisible(false)
    onReset()
    paramsRef.current.page = 1
    await dispatch(getDictionaryList())
  }
  // 查询字典
  const onSeach = async (values) => {
    console.log(values)
    paramsRef.current.co_id = values.co_id
    paramsRef.current.fuzzy_name = values.fuzzy_name
    paramsRef.current.page = 1
    await dispatch(getDictionaryList(paramsRef.current))
  }
  const [form] = Form.useForm()
  const onReset = () => {
    form.resetFields()
  }

  const editable = {
    // defaultDom = {save,cancel,delete} 可以酌情添加和使用
    actionRender: (row, config, defaultDom) => {
      console.log(row, config, defaultDom)
      return [defaultDom.save, defaultDom.delete]
    },
  }

  const changeComp = (value) => {
    console.log(value)
    setCoId(value)
  }

  const tabRef = useRef()
  const [errorList, setErrorList] = useState([])

  useEffect(() => {
    console.log('====================>', errorList)
  }, [errorList])
  return (
    <div className={styles.root}>
      <div className="right_content">
        <Breadcrumb>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Item>数据字典</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="search-card">
          <Form
            autoComplete="off"
            form={form}
            className="form-search"
            onFinish={onSeach}
          >
            <div className="flex">
              {getSuperGroup() === 'true' ? (
                <Form.Item label="企业/产品" name="co_id">
                  <Select placeholder="请选择" style={{ width: 238 }}>
                    {compListAll.map((item) => (
                      <Select.Option value={item.co_id} key={item.co_id}>
                        {item.co_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}

              <Form.Item label="关键字" name="fuzzy_name">
                <Input
                  placeholder="请输入数据字典编码或名称"
                  style={{ width: 238 }}
                ></Input>
              </Form.Item>
            </div>
            <Form.Item>
              <Space size={[20]}>
                <MyButton type="default" htmlType="submit">
                  查询
                </MyButton>
                <MyButton onClick={onReset}>重置</MyButton>
              </Space>
            </Form.Item>
          </Form>
        </Card>
        <Space size={[24]} className="space-heigth">
          <MyButton type="default" onClick={showVisible}>
            新增
          </MyButton>
        </Space>
        <div className="right_table" ref={tableRef}>
          <ConfigProvider locale={zhCH}>
            <Table
              scroll={{
                y: size?.height - 95 + 'px',
              }}
              size="small"
              onChange={onChange}
              rowKey="dic_id"
              rowSelection={{ ...rowSelection }}
              columns={columns}
              dataSource={dictionaryList}
              showCount
              pagination={paginations}
            ></Table>
          </ConfigProvider>
        </div>
      </div>
      {/* 新增字典 */}
      <Drawer
        width={624}
        destroyOnClose={true}
        size="large"
        maskClosable={false}
        // getContainer={false}
        title={dicInfo.dic_id ? '修改字典' : '新增字典'}
        placement="right"
        onClose={() => {
          setVisible(false)
        }}
        visible={visible}
      >
        <Form
          autoComplete="off"
          validateTrigger={['onBlur', 'onChange']}
          ref={formRef}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          initialValues={{
            is_default_tmpl: false,
          }}
          onFinish={onFinish}
        >
          <div className="form-btn">
            <div className="flex">
              {getSuperGroup() === 'true' ? (
                <Form.Item
                  label="企业/产品"
                  name="co_id"
                  className="w-50"
                  rules={rulesObj.current.co_id}
                >
                  <Select
                    onChange={changeComp}
                    disabled={dicInfo.dic_id ? true : false}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    placeholder="请选择企业/产品"
                  >
                    {compListAll.map((item) => (
                      <Select.Option value={item.co_id} key={item.co_id}>
                        {item.co_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}
              <Form.Item
                className="w-50"
                label="数据字典编码"
                name="dic_code"
                rules={rulesObj.current.dic_code}
              >
                <Input maxLength={50} placeholder="不超过50个字"></Input>
              </Form.Item>
            </div>
            <Form.Item
              label="数据字典名称"
              name="dic_name"
              rules={rulesObj.current.dic_name}
            >
              <Input maxLength={50} placeholder="不超过50个字"></Input>
            </Form.Item>

            <Form.Item
              label="描述"
              name="dic_desc"
              rules={rulesObj.current.dic_desc}
            >
              <Input.TextArea
                maxLength={500}
                showCount
                placeholder="不超过500个字"
                style={{ height: '100px' }}
              ></Input.TextArea>
            </Form.Item>
            <Form.Item
              label="条目"
              name="dic_items"
              style={{ marginBottom: 0 }}
            ></Form.Item>
            <TableContext.Provider value={setErrorList}>
              <EditableTable
                ref={tabRef}
                value={dicInfo.dic_items || []}
                // onChange={setDataSource}
              ></EditableTable>
            </TableContext.Provider>
          </div>
          <Form.Item className="right-bottom">
            <div className="flex_end">
              <Space>
                <MyButton onClick={() => setVisible(false)}>取消</MyButton>
                <MyButton
                  type="default"
                  className="default"
                  htmlType="submit"
                  // disabled={errorList.length !== 0}
                >
                  {dicInfo.dic_id ? '修改' : '新增'}
                </MyButton>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Drawer>

      {/* 查看抽屉 */}
      <Drawer
        width={624}
        title="查看字典"
        visible={watch}
        destroyOnClose={true}
        size="large"
        placement="right"
        onClose={() => {
          setWatch(false)
        }}
      >
        <div style={{ minHeight: 'calc(100% - 30px)' }}>
          <div className="msg">
            <span className="linear"></span> 基本信息
          </div>
          <div className="watch_marg">
            {getSuperGroup() === 'true' ? (
              <p>
                <span>企业/产品：</span>
                {dicInfo.co_name}
              </p>
            ) : (
              ''
            )}
            <p>
              <span>数据字典编码：</span>
              {dicInfo.dic_code}
            </p>
            <p>
              <span>数据字典名称：</span>
              {dicInfo.dic_name}
            </p>
            <p className="desc">
              <span>描述：</span>
              <Tooltip
                overlayStyle={{ maxWidth: 560 }}
                placement="leftTop"
                className="desc_text"
                title={dicInfo.dic_desc}
              >
                {dicInfo.dic_desc}
              </Tooltip>
            </p>
            <p>
              <span>条目:</span>
            </p>
            <Table
              size="small"
              columns={watchColumns}
              dataSource={dicInfo.dic_items}
              pagination={false}
            ></Table>
          </div>
        </div>
        <MyButton
          type="default"
          className=" ant-spaces"
          onClick={() => setWatch(false)}
        >
          关闭
        </MyButton>
      </Drawer>

      {/* 删除字典对话框 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setDelDicModal(false)}
        title="删除字典"
        visible={delDicModal}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setDelDicModal(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => delDic(dicInfo.dic_id)}>
              删除
            </MyButton>
          </Space>,
        ]}
      >
        <p>确定要删除数据字典“{dicInfo.dic_name}”吗？</p>
      </Modal>
    </div>
  )
}
