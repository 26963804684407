import {
  GETDICTIONARY,
  GETLOGINSTATUS,
  GETOPTLOG,
  GETOPTTYPE,
} from '../constants'

const init = {
  total: 0,
  dictionaryList: [],
  optTypeList: [],
  optStatusList: [],
}

export default function getDictionary(state = init, action) {
  if (action.type === GETDICTIONARY) {
    // console.log('123213')
    return {
      ...state,
      total: action.payload.total,
      dictionaryList: action.payload.data,
    }
  }
  if (action.type === GETOPTTYPE) {
    return {
      ...state,
      optTypeList: action.payload,
    }
  }
  if (action.type === GETLOGINSTATUS) {
    return {
      ...state,
      optStatusList: action.payload,
    }
  }
  return state
}
