import { useSize } from 'ahooks'
import React, { useEffect, useRef } from 'react'
import styles from './index.module.scss'
import {
  Breadcrumb,
  Card,
  Form,
  Input,
  Select,
  ConfigProvider,
  Space,
  Table,
  Tabs,
  DatePicker,
  Tooltip,
} from 'antd'
import MyButton from '../../../../components/MyButton'
import zhCH from 'antd/lib/locale/zh_CN'
import 'moment/locale/zh-cn'
import locale from 'antd/es/date-picker/locale/zh_CN'
import { getCompany, getSuperGroup } from '../../../../utils/cookie'
import { useDispatch, useSelector } from 'react-redux'
import { getCompLists } from '../../../../store/actions/comp'
import { getLoginLog, getOptLog } from '../../../../store/actions/login'
import {
  getLoginStatus,
  getOptType,
} from '../../../../store/actions/dictionary'

const { TabPane } = Tabs
const { RangePicker } = DatePicker
export default function JournalManage() {
  const dispatch = useDispatch()
  const tableRef = useRef(null)
  const size = useSize(tableRef)

  useEffect(() => {
    getSuperGroup() === 'true' &&
      dispatch(
        getCompLists({
          is_page: true,
        })
      )
  }, [dispatch])

  useEffect(() => {
    dispatch(getLoginLog())
  }, [dispatch])

  useEffect(() => {
    dispatch(getOptLog())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getOptType({
        co_id: getCompany(),
        dic_code: 'SYS_OPT_TYPE',
        is_page: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getLoginStatus({
        co_id: getCompany(),
        dic_code: 'SYS_OPT_RESULT',
        is_page: true,
      })
    )
  }, [dispatch])

  const { compListAll } = useSelector((state) => state.comp)
  const { loginLog, loginTotal, optTotla, optLog } = useSelector(
    (state) => state.login
  )
  console.log(optLog, optTotla)
  const { optStatusList, optTypeList } = useSelector(
    (state) => state.dictionary
  )
  const paramsRef = useRef({
    page: 1,
    page_size: 10,
  })
  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])
  // 登录日志
  const logColumns =
    getSuperGroup() === 'true'
      ? [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              // console.log(text, record, index)
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '企业/产品',
            dataIndex: 'user',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={record.co_name}>
                {record.co_name}
              </Tooltip>
            ),
          },
          {
            title: '用户名',
            dataIndex: 'user_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作类型',
            dataIndex: 'opt_type_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              return (
                <Tooltip placement="leftTop" title={text}>
                  {text}
                </Tooltip>
              )
            },
          },
          {
            title: '时间',
            dataIndex: 'opt_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '登录状态',
            dataIndex: 'login_status',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '登录方式',
            dataIndex: 'login_method',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: 'IP地址',
            dataIndex: 'ip_address',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作系统',
            dataIndex: 'opt_system',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '终端类型',
            dataIndex: 'terminal_type',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '浏览器版本',
            dataIndex: 'browser_version',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '详细信息',
            dataIndex: 'detail_info',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
        ]
      : [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              // console.log(text, record, index)
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '用户名',
            dataIndex: 'user_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作类型',
            dataIndex: 'opt_type_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              return (
                <Tooltip placement="leftTop" title={text}>
                  {text}
                </Tooltip>
              )
            },
          },
          {
            title: '时间',
            dataIndex: 'opt_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '登录状态',
            dataIndex: 'login_status',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '登录方式',
            dataIndex: 'login_method',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: 'IP地址',
            dataIndex: 'ip_address',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作系统',
            dataIndex: 'opt_system',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '终端类型',
            dataIndex: 'terminal_type',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '浏览器版本',
            dataIndex: 'browser_version',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '详细信息',
            dataIndex: 'detail_info',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
        ]
  // 操作日志
  const oprColumns =
    getSuperGroup() === 'true'
      ? [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              // console.log(text, record, index)
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '企业/产品',
            dataIndex: 'user',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={record.co_name}>
                {record.co_name}
              </Tooltip>
            ),
          },
          {
            title: '用户名',
            dataIndex: 'user_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作类型',
            dataIndex: 'opt_type_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              return (
                <Tooltip placement="leftTop" title={text}>
                  {text}
                </Tooltip>
              )
            },
          },
          {
            title: '时间',
            dataIndex: 'opt_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作结果',
            dataIndex: 'opt_result',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },

          {
            title: '详细信息',
            dataIndex: 'detail_info',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
        ]
      : [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              // console.log(text, record, index)
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '用户名',
            dataIndex: 'user_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作类型',
            dataIndex: 'opt_type_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              return (
                <Tooltip placement="leftTop" title={text}>
                  {text}
                </Tooltip>
              )
            },
          },
          {
            title: '时间',
            dataIndex: 'opt_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作结果',
            dataIndex: 'opt_result',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },

          {
            title: '详细信息',
            dataIndex: 'detail_info',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
        ]
  const callback = (key) => {
    console.log(key)
  }

  const [form] = Form.useForm()
  const onReset = () => {
    form.resetFields()
  }
  // 多选框
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, selectedRows)
      // setBatchId(`${selectedRowKeys}`)
    },
  }

  const paginations = {
    defaultCurrent: 1,
    current: paramsRef.current.page,
    defaultPageSize: 10,
    total: loginTotal,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (loginTotal) => {
      return `共${loginTotal}条`
    },
  }
  const pagination = {
    defaultCurrent: 1,
    current: paramsRef.current.page,
    defaultPageSize: 10,
    total: optTotla,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (optTotla) => {
      return `共${optTotla}条`
    },
  }

  const changeLog = (page, pageSize) => {
    console.log(page, pageSize)
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    console.log(paramsRef.current)
    dispatch(getLoginLog(paramsRef.current))
  }
  const changeOpt = (page, pageSize) => {
    console.log(page, pageSize)
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    console.log(paramsRef.current)
    dispatch(getOptLog(paramsRef.current))
  }

  const searchLog = async (values) => {
    // console.log('12312')
    paramsRef.current.opt_time__gte = values.time ? values.time[0]._d : null
    paramsRef.current.opt_time__lte = values.time ? values.time[1]._d : null
    paramsRef.current.page = 1
    await dispatch(
      getLoginLog({
        ...values,
        ...paramsRef.current,
      })
    )
  }
  const searchOpt = async (values) => {
    console.log(values)
    paramsRef.current.opt_time__gte = values.time ? values.time[0]._d : null
    paramsRef.current.opt_time__lte = values.time ? values.time[1]._d : null
    paramsRef.current.page = 1
    await dispatch(
      getOptLog({
        ...values,
        ...paramsRef.current,
      })
    )
  }

  return (
    <ConfigProvider locale={zhCH}>
      <div className={styles.root}>
        <div className="right_content">
          <Breadcrumb>
            <Breadcrumb.Item>系统管理</Breadcrumb.Item>
            <Breadcrumb.Item>日志管理</Breadcrumb.Item>
          </Breadcrumb>

          <Card style={{ height: '100%' }}>
            <Tabs
              style={{ height: '100%' }}
              defaultActiveKey="1"
              onChange={callback}
            >
              <TabPane tab="登录日志" key="1" style={{ height: '100%' }}>
                <div className="log-search-card">
                  <Form
                    form={form}
                    autoComplete="off"
                    // className="log-search"
                    onFinish={searchLog}
                  >
                    <div className="flex">
                      {getSuperGroup() === 'true' ? (
                        <Form.Item label="企业/产品" name="co_id">
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            placeholder="请选择企业/产品"
                            style={{ width: 238 }}
                          >
                            {compListAll.map((item) => (
                              <Select.Option
                                value={item.co_id}
                                key={item.co_id}
                              >
                                {item.co_name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : (
                        ''
                      )}
                      <Form.Item label="用户名" name="user_name">
                        <Input
                          placeholder="请输入用户名"
                          style={{ width: 238 }}
                        ></Input>
                      </Form.Item>
                      <Form.Item label="ip地址" name="ip_address">
                        <Input
                          placeholder="请输入ip地址"
                          style={{ width: 238 }}
                        ></Input>
                      </Form.Item>
                      <Form.Item label="操作类型" name="opt_type_name">
                        <Select
                          placeholder="请选择操作类型"
                          style={{ width: 238 }}
                        >
                          {optTypeList.map((item) => {
                            return (
                              <Select.Option
                                value={item.dic_item_code}
                                key={item.dic_item_id}
                              >
                                {item.dic_item_name}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="flex">
                      <Form.Item label="登录状态" name="login_status">
                        <Select
                          placeholder="请选择登录状态"
                          style={{ width: 238 }}
                        >
                          {optStatusList.map((item) => {
                            return (
                              <Select.Option
                                value={item.dic_item_code}
                                key={item.dic_item_id}
                              >
                                {item.dic_item_name}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item label="时间" name="time">
                        <RangePicker
                          locale={locale}
                          showTime
                          style={{ width: 380 }}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Space size={[20]}>
                          <MyButton type="default" htmlType="submit">
                            查询
                          </MyButton>
                          <MyButton onClick={onReset}>重置</MyButton>
                        </Space>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
                <Space size={[24]} className="space-heigth">
                  <MyButton type="default">导出</MyButton>
                </Space>
                <div className="log_table" ref={tableRef}>
                  <Table
                    scroll={{ y: size?.height - 100 + 'px' }}
                    size="small"
                    onChange={changeLog}
                    rowKey="log_id"
                    rowSelection={{ ...rowSelection }}
                    columns={logColumns}
                    dataSource={loginLog}
                    showCount
                    pagination={paginations}
                  ></Table>
                </div>
              </TabPane>
              <TabPane tab="操作日志" key="2">
                <div className="log-search-card">
                  <Form
                    form={form}
                    autoComplete="off"
                    // className="log-search"
                    onFinish={searchOpt}
                  >
                    <div className="flex">
                      {getSuperGroup() === 'true' ? (
                        <Form.Item label="企业/产品" name="co_id">
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            placeholder="请选择企业/产品"
                            style={{ width: 238 }}
                          >
                            {compListAll.map((item) => (
                              <Select.Option
                                value={item.co_id}
                                key={item.co_id}
                              >
                                {item.co_name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : (
                        ''
                      )}
                      <Form.Item label="用户名" name="user_name">
                        <Input
                          placeholder="请输入用户名"
                          style={{ width: 238 }}
                        ></Input>
                      </Form.Item>

                      <Form.Item label="操作类型" name="opt_type_name">
                        <Select
                          placeholder="请选择操作类型"
                          style={{ width: 238 }}
                        >
                          {optTypeList.map((item) => {
                            return (
                              <Select.Option
                                value={item.dic_item_code}
                                key={item.dic_item_id}
                              >
                                {item.dic_item_name}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item label="操作结果" name="opt_result">
                        <Select
                          placeholder="请选择操作结果"
                          style={{ width: 238 }}
                        >
                          {optStatusList.map((item) => {
                            return (
                              <Select.Option
                                value={item.dic_item_code}
                                key={item.dic_item_id}
                              >
                                {item.dic_item_name}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="flex">
                      <Form.Item label="时间" name="time">
                        <RangePicker
                          locale={locale}
                          showTime
                          style={{ width: 380 }}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Space size={[20]}>
                          <MyButton type="default" htmlType="submit">
                            查询
                          </MyButton>
                          <MyButton onClick={onReset}>重置</MyButton>
                        </Space>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
                <Space size={[24]} className="space-heigth">
                  <MyButton type="default">导出</MyButton>
                </Space>
                <div className="log_table" ref={tableRef}>
                  <Table
                    // scroll={{ y: size?.height - 100 + 'px' }}
                    size="small"
                    onChange={changeOpt}
                    rowKey="opt_id"
                    rowSelection={{ ...rowSelection }}
                    columns={oprColumns}
                    dataSource={optLog}
                    showCount
                    pagination={pagination}
                  ></Table>
                </div>
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    </ConfigProvider>
  )
}
