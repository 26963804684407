import request from '../../utils/request'
import {
  GETLOGINLOG,
  GETMENUS,
  GETOPTLOG,
  LOGIN,
  LOGOUT,
  REGISTERUSER,
  USERINFO,
} from '../constants'
import qs from 'qs'
import {
  removeCompany,
  removeSuperGroup,
  removeToken,
  setCompany,
  setSuperGroup,
  setToken,
} from '../../utils/cookie'
import { useHistory } from 'react-router-dom'
/**
 * 登入
 * @param {*} payload
 * @returns
 */
export const login = (params) => {
  let url = document.location.href
  console.log(url)
  let ticket = ''
  if (/ticket=(ST-[^#/?]+)/.test(url)) {
    ticket = /ticket=(ST-[^#/?]+)/.exec(url)[1]
  }
  return async (dispatch) => {
    const res = await request.get('sys/login/', {
      params: {
        ticket,
        redirect: window.location.origin + window.location.pathname,
      },
    })
    // console.log(res)
    if (res.data.data.status === 'redirect') {
      window.location.href = res.data.data.login_url
      return false
    } else if (res.data.data.status === 'login') {
      const token = res.data.data.token
      setToken(token)
      const super_group = res.data.data.super_group
      setSuperGroup(super_group)
      const company = res.data.data.company
      setCompany(company)
      dispatch({
        type: LOGIN,
        payload: res.data.data,
      })
      return true
    }
  }
}
/**
 * 登出
 * @returns
 */
export const logout = () => {
  return async (dispatch) => {
    const res = await request.post('sys/logout/', {
      redirect: window.location.origin + window.location.pathname,
    })
    // console.log(res.data.data.logout_url)
    console.log(res)
    if (res.data.data.status === 'redirect') {
      window.location.href = res.data.data.logout_url
      removeToken()
      removeSuperGroup()
      removeCompany()
    }

    dispatch({
      type: LOGOUT,
    })
  }
}

export const changePassword = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/password/change/', values)
  }
}

export const getMenus = (params) => {
  return async (dispatch) => {
    const res = await request.get('/sys/user/menu/')

    dispatch({
      type: GETMENUS,
      payload: res.data.data,
    })
  }
}

export const getUserInfo = () => {
  return async (dispatch) => {
    const res = await request.get('sys/user/info/')
    // console.log(res.data.data)
    dispatch({
      type: USERINFO,
      payload: res.data.data,
    })
  }
}

export const getLoginLog = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/log/login/', { params })
    console.log(res)
    dispatch({
      type: GETLOGINLOG,
      payload: res.data.data,
    })
  }
}

export const getOptLog = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/log/opt/', { params })
    // console.log(res.data.data)
    dispatch({
      type: GETOPTLOG,
      payload: res.data.data,
    })
  }
}

export const registerUser = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/registration/', values)
    dispatch({
      type: REGISTERUSER,
      payload: res.data.data,
    })
  }
}
