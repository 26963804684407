import request from '../../utils/request'
import { GETPRODUCTCFGTEMP } from '../constants'

/**
 * 获取产品配置模板
 * @param {*} params
 * @returns
 */
export const getCompTemp = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/comp/', {
      params,
    })
    dispatch({
      type: GETPRODUCTCFGTEMP,
      payload: res.data.data,
    })
  }
}
