import { hasToken } from '../../utils/cookie'
import { Redirect, Route, useLocation } from 'react-router-dom'
const whiteList = ['/login', 'loginHtml', '/authredirect', '/404']

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (hasToken()) {
          // getUserInfo(); // 获取用户信息，用于前端监控系统
          if (props.location === 'loginHtml') {
            return (
              <Redirect
                to={{
                  pathname: '/loginHtml',
                }}
              />
            )
          } else {
            return <Component {...props} />
          }
        } else {
          if (whiteList.indexOf(props.location) >= 0) {
            return <Component {...props} />
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/loginHtml',
                  search: '?redirect=' + props.match.url,
                }}
              />
            )
          }
        }
      }}
    />
  )
}

export default PrivateRoute
