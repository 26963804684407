import { Router, Route, Switch, Redirect } from 'react-router-dom'
import LoginCas from './pages/Login/loginCas'
import Layout from './pages/Layout'
import PrivateRoute from './components/PrivateRoute'
import history from './utils/history'
import Login from './pages/Login'
import Help from './pages/Help'
import ServiceAgreement from './pages/ServiceAgreement'
import PrivacyAgreement from './pages/PrivacyAgreement'
import ContactInformation from './pages/ContactInformation'
export default function App() {
  // if (hasToken()) {
  //   return <Layout></Layout>
  // } else {
  //   return <Login></Login>
  // }
  return (
    <Router history={history}>
      <Switch>
        <Redirect exact from="/" to="/home"></Redirect>
        <Route path="/loginHtml">{<Login></Login>}</Route>
        <Route path="/help/:id">{<Help></Help>}</Route>
        <Redirect exact from="/help" to="/help/10002.md"></Redirect>

        <Route path="/legal-agreement/tos.html">{<ServiceAgreement />}</Route>
        <Route path="/legal-agreement/privacy.html">
          {<PrivacyAgreement />}
        </Route>
        <Route path="/legal-agreement/about.html">
          {<ContactInformation />}
        </Route>
        <Route path="/login">{<LoginCas />}</Route>
        <PrivateRoute path="/home">{<Layout />}</PrivateRoute>
        <Redirect path="*" to="/home"></Redirect>
      </Switch>
    </Router>
  )
}
