import React, { useRef } from 'react'
import {
  Layout,
  Breadcrumb,
  Card,
  Form,
  Input,
  Button,
  Space,
  Table,
  Drawer,
  TreeSelect,
  message,
  Modal,
  Radio,
  Upload,
  ConfigProvider,
  Tooltip,
  Select,
} from 'antd'

import styles from './index.module.scss'
import { Tree } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import { useState } from 'react'
import {
  addMenus,
  delMenus,
  editMenus,
  getMenuList,
  getMenuTreeList,
  getTrigger,
  moveMenus,
} from '../../../../store/actions/menu'

import zhCH from 'antd/lib/locale/zh_CN'
import { getCompany, getSuperGroup } from '../../../../utils/cookie'
import { getCompLists } from '../../../../store/actions/comp'
import { getMenus } from '../../../../store/actions/login'
import { useSize, useGetState } from 'ahooks'
import MyButton from '../../../../components/MyButton'
const { Sider, Content } = Layout

export default function MenuManage() {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [lastMenu, setLastMenu] = useState('')
  const [menuInfo, setMenuInfo, getMenuInfo] = useGetState({})
  const [radio, setRadio] = useState('CURRENT_PAGE')
  const [delModal, setDelModal] = useState(false)
  const [moveModal, setMoveModal] = useState(false)
  const [formData, setFormData] = useState()
  const [compId, setCompId, getCompId] = useGetState('')
  const [imgUrl, setImgUrl] = useState('')
  const { menuTreeList } = useSelector((state) => state.menu)
  const { total, menuAllList } = useSelector((state) => state.menu)
  const { compListAll } = useSelector((state) => state.comp)

  const formRef = useRef()
  const paramsRef = useRef({
    page: 1,
    page_size: 10,
    isSearch: false,
  })
  const addRef = useRef()
  const iptRef = useRef()
  const tableRef = useRef(null)
  const size = useSize(tableRef)

  const rulesObj = useRef({
    co_id: [
      {
        required: true,
        message: '请选择企业/产品',
      },
    ],
    menu_code: [
      {
        required: true,
        message: '菜单编号不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
      {
        validator(_, value) {
          console.log(getMenuInfo())
          return dispatch(
            getTrigger(
              {
                pk: getMenuInfo().menu_id ? getMenuInfo().menu_id : null,
                co_id: getMenuInfo().co_id ? getMenuInfo().co_id  : null,

                field: 'code',
                value,
              },
              false
            )
          ).then(({ data }) => {
            if (!data.data) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('菜单编号已存在'))
          })
        },
        validateTrigger: 'onBlur',
      },
    ],
    menu_name: [
      {
        required: true,
        message: '菜单名称不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
    menu_url: [
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
    menu_desc: [
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
  })

  // 树形数据
  useEffect(() => {
    dispatch(
      getMenuTreeList({
        co_id: getCompany(),
      })
    )
    getMenuComment()
  }, [])

  useEffect(() => {
    if (compListAll.length > 0) {
      setCompId(getCompany())
    }
  }, [compListAll])

  //获取企业/产品列表
  useEffect(() => {
    getSuperGroup() === 'true' &&
      dispatch(
        getCompLists({
          is_page: true,
        })
      )
  }, [dispatch])

  // 数据回显
  useEffect(() => {
    const values = { ...menuInfo }
    // console.log('123', menuInfo)
    setTimeout(() => {
      addRef.current?.setFieldsValue({
        ...values,
        parent: values.parent_name,
        parent_name: leftId === undefined ? '全部' : leftId,
        co_id: menuInfo.co_id,
      })
    }, 0)

    setImgUrl(values.icon)
  }, [menuInfo])

  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])
  const columns =
    getSuperGroup() === 'true'
      ? [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '企业/产品',
            dataIndex: 'user',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={record.co_name}>
                {record.co_name}
              </Tooltip>
            ),
          },
          {
            title: '菜单编号',
            dataIndex: 'menu_code',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '菜单名称',
            dataIndex: 'menu_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '上级菜单',
            dataIndex: 'parent_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '菜单描述',
            dataIndex: 'menu_desc',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              return (
                <Tooltip
                  overlayStyle={{ maxWidth: 560 }}
                  placement="leftTop"
                  title={text}
                >
                  {text}
                </Tooltip>
              )
            },
          },
          {
            title: '菜单URL',
            dataIndex: 'menu_url',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '打开方式',
            dataIndex: 'open_mode',
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'menuInfo',
            width: 240,
            fixed: 'right',
            render: (menu, index, i) => {
              // console.log(menu)
              return (
                <Space>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => editMenu(menu)}
                  >
                    修改
                  </Button>

                  <Button
                    onClick={() => {
                      setMoveModal(true)
                      setMoveValue(null)
                      setMenuInfo(menu)
                      setLastMenu('')
                    }}
                    type="link"
                    disabled={
                      menuAllList.length === i || paramsRef.current.isSearch
                    }
                    className={{
                      edit:
                        menuAllList.length === i || !paramsRef.current.isSearch,
                    }}
                  >
                    移动
                  </Button>
                  <Button
                    onClick={() => {
                      changeUp(menu.menu_id, menu.parent)
                    }}
                    type="link"
                    disabled={i === 0 || paramsRef.current.isSearch}
                    className={{ edit: i !== 0 && !paramsRef.current.isSearch }}
                  >
                    上移
                  </Button>
                  <Button
                    onClick={() => {
                      changeDown(menu.menu_id, menu.parent)
                    }}
                    type="link"
                    disabled={
                      menuAllList.length - 1 === i || paramsRef.current.isSearch
                    }
                    className={{
                      edit:
                        menuAllList.length - 1 !== i &&
                        !paramsRef.current.isSearch,
                    }}
                  >
                    下移
                  </Button>
                  <Button
                    type="link"
                    className="del"
                    onClick={() => showDelModal(menu)}
                  >
                    删除
                  </Button>
                </Space>
              )
            },
          },
        ]
      : [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '菜单编号',
            dataIndex: 'menu_code',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '菜单名称',
            dataIndex: 'menu_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '上级菜单',
            dataIndex: 'parent_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '菜单描述',
            dataIndex: 'menu_desc',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              return (
                <Tooltip
                  overlayStyle={{ maxWidth: 560 }}
                  placement="leftTop"
                  title={text}
                >
                  {text}
                </Tooltip>
              )
            },
          },
          {
            title: '菜单URL',
            dataIndex: 'menu_url',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '打开方式',
            dataIndex: 'open_mode',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'menuInfo',
            width: 240,
            fixed: 'right',
            render: (menu, index, i) => {
              // console.log(menu)
              return (
                <Space>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => editMenu(menu)}
                  >
                    修改
                  </Button>

                  <Button
                    onClick={() => {
                      setMoveModal(true)
                      setMoveValue(null)
                      setMenuInfo(menu)
                      setLastMenu('')
                    }}
                    type="link"
                    disabled={
                      menuAllList.length === i || paramsRef.current.isSearch
                    }
                    className={{
                      edit:
                        menuAllList.length === i || !paramsRef.current.isSearch,
                    }}
                  >
                    移动
                  </Button>
                  <Button
                    onClick={() => {
                      changeUp(menu.menu_id, menu.parent)
                    }}
                    type="link"
                    disabled={i === 0 || paramsRef.current.isSearch}
                    className={{ edit: i !== 0 && !paramsRef.current.isSearch }}
                  >
                    上移
                  </Button>
                  <Button
                    onClick={() => {
                      changeDown(menu.menu_id, menu.parent)
                    }}
                    type="link"
                    disabled={
                      menuAllList.length - 1 === i || paramsRef.current.isSearch
                    }
                    className={{
                      edit:
                        menuAllList.length - 1 !== i &&
                        !paramsRef.current.isSearch,
                    }}
                  >
                    下移
                  </Button>
                  <Button
                    type="link"
                    className="del"
                    onClick={() => showDelModal(menu)}
                  >
                    删除
                  </Button>
                </Space>
              )
            },
          },
        ]

  const getMenuComment = (search) => {
    let params = {}
    if (!paramsRef.current.isSearch) {
      // params.co_id = paramsRef.current.co_id
      params.co_id = compId
      params.parent = paramsRef.current.menu_id
      params.top = params.parent ? null : true
    } else {
      params.fuzzy_name = formRef.current.getFieldValue('fuzzy_name')
    }
    params.is_page = true
    params.page = paramsRef.current.page
    params.pageSize = paramsRef.current.pageSize
    dispatch(getMenuList(params))
  }
  // 左侧树形数据右侧渲染
  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info)
    setLeftId(info.node.menu_name)
    setLastMenu(selectedKeys[0] === '全部' ? '' : selectedKeys[0])
    paramsRef.current = info.node
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
    paramsRef.current.isSearch = false
    getMenuComment()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(selectedRowKeys)
    },
  }
  const [leftId, setLeftId] = useState('')
  // 新增弹窗
  const showVisible = () => {
    console.log('新增')
    let menu = {
      co_id: compId,
      parent_name: leftId === undefined ? '全部' : leftId,
    }
    setMenuInfo(menu)
    setVisible(true)
    setImgUrl(undefined)
  }
  // 修改弹窗
  const editMenu = (menu) => {
    console.log('修改菜单', menu)
    menu = JSON.parse(JSON.stringify(menu))
    setMenuInfo(menu)
    setVisible(true)
  }
  // 删除弹窗
  const showDelModal = (menu) => {
    setMenuInfo(menu)
    setDelModal(true)
  }
  // 新增菜单
  const onFinish = async (values) => {
    console.log(values)
    //  menuInfo.menu_id
    if (menuInfo.menu_id) {
      values.parent = menuInfo.parent
      const formData = new FormData()
      console.log(menuInfo.menu_id)
      // formData.append(iptRef.current.files[0] ? 'icon', iptRef.current.files[0] || '')
      // formData.append('id', menuInfo.menu_id)
      const file = iptRef.current.files[0]
      if (file) {
        formData.append('icon', file)
        formData.append('menu_code', values.menu_code || '')
        formData.append('co_id', values.co_id || '')
        formData.append('menu_name', values.menu_name || '')
        formData.append('menu_url', values.menu_url || '')
        formData.append('open_mode', values.open_mode || '')
        formData.append('menu_desc', values.menu_desc || '')
        formData.append('parent', values.parent || '')
      } else {
        formData.append('menu_code', values.menu_code || '')
        formData.append('co_id', values.co_id || '')
        formData.append('menu_name', values.menu_name || '')
        formData.append('menu_url', values.menu_url || '')
        formData.append('open_mode', values.open_mode || '')
        formData.append('menu_desc', values.menu_desc || '')
        formData.append('parent', values.parent || '')
      }

      console.log(iptRef.current.files[0])

      await dispatch(editMenus(formData, { id: menuInfo.menu_id }))
    } else {
      values.parent = lastMenu
      const formData = new FormData()
      // setLastMenu('')
      formData.append('co_id', compId)
      formData.append('icon', iptRef.current.files[0] || '')
      formData.append('menu_code', values.menu_code || '')
      formData.append('menu_name', values.menu_name || '')
      formData.append('menu_url', values.menu_url || '')
      formData.append('open_mode', values.open_mode || '')
      formData.append('menu_desc', values.menu_desc || '')
      formData.append('parent', values.parent || '')
      await dispatch(addMenus(formData))
      setImgUrl('')
    }
    message.success(menuInfo.menu_id ? '修改菜单成功' : '新增菜单成功')
    paramsRef.current.page = 1
    setVisible(false)
    getMenuComment()
    await dispatch(
      getMenuTreeList({
        co_id: compId,
        top: true,
      })
    )
    // await dispatch(
    //   getMenus({
    //     co_id: getCompany(),
    //   })
    // )
  }
  const [moveValue, setMoveValue] = useState(null)

  // 选择菜单
  const changeMenu = (value, node) => {
    console.log(value, node.menu_id)
    setMoveValue(value)
    setLastMenu(node.menu_id)
  }
  // 搜索所有菜单
  const search = async (values) => {
    console.log(values)
    paramsRef.current.isSearch = true
    paramsRef.current.page = 1
    await getMenuComment()
  }

  // 分页处理
  const onChange = (page, pageSize) => {
    // console.log(page, pageSize)
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    getMenuComment()
  }

  // 删除菜单
  const delMenu = async (id) => {
    // console.log(id)
    await dispatch(delMenus(id))
    message.success('删除菜单成功')
    paramsRef.current.page = 1
    setDelModal(false)
    getMenuComment()
    await dispatch(
      getMenuTreeList({
        co_id: compId,
        top: true,
      })
    )
    // await dispatch(
    //   getMenus({
    //     co_id: getCompany(),
    //   })
    // )
  }

  //上移
  const changeUp = async (id, parent) => {
    console.log('上移', id, parent)
    await dispatch(
      moveMenus({
        pk: id,
        action: 'up',
        parent: parent,
      })
    )
    await dispatch(
      getMenuTreeList({
        co_id: menuId,
        top: true,
      })
    )

    message.success('上移成功')
    // await dispatch(
    //   getMenus({
    //     co_id: getCompany(),
    //   })
    // )
    getMenuComment()
  }
  // 下移
  const changeDown = async (id, parent) => {
    console.log('下移', id, parent)
    await dispatch(
      moveMenus({
        pk: id,
        action: 'down',
        parent: parent,
      })
    )
    await dispatch(
      getMenuTreeList({
        co_id: menuId,
        top: true,
      })
    )
    // await dispatch(
    //   getMenus({
    //     co_id: getCompany(),
    //   })
    // )
    message.success('下移成功')
    getMenuComment()
  }

  // 移动弹窗
  const changeMove = async (params) => {
    if (!moveValue) {
      return message.error('请选择菜单')
    }
    console.log(params)
    await dispatch(
      moveMenus({
        pk: params.menu_id,
        action: 'move',
        parent: lastMenu ? lastMenu : null,
      })
    )
    message.success('移动成功')
    setMoveModal(false)
    getMenuComment()
    await dispatch(
      getMenuTreeList({
        co_id: compId,
        top: true,
      })
    )
    // await dispatch(
    //   getMenus({
    //     co_id: getCompany(),
    //   })
    // )
  }

  const uploadIcon = () => {
    iptRef.current.click()
  }

  const onUploadInputFile = () => {
    // console.log(iptRef.current.files)
    if (iptRef.current.files.length === 0) {
      message.warn('请上传16*16px图标')
      return
    }
    const reader = new FileReader()
    reader.onload = function fileReadCompleted() {
      console.log(reader.result)
      setImgUrl(reader.result)
    }
    reader.readAsDataURL(iptRef.current.files[0])
    const formData = new FormData()
    formData.append('file', iptRef.current.files[0])
    setFormData(formData)
  }

  function getBase64(img, callback) {
    console.log(img, callback)
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const paginations = {
    defaultCurrent: 1,
    current: paramsRef.current.page,
    defaultPageSize: 10,
    total: total,
    // showSizeChanger: true,
    // pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => {
      return `共${total}条`
    },
  }

  const onSearch = (val) => {
    console.log('123', val)
  }

  const [menuId, setMenuId] = useState(getCompany())

  const change = async (value) => {
    setCompId(value)
    setMenuId(value)
    console.log(`selected ${value}`)
    await dispatch(
      getMenuTreeList({
        co_id: value,
        top: true,
        is_page: true,
      })
    )
    await dispatch(
      getMenuList({
        co_id: value,
        top: true,
        is_page: true,
      })
    )
    setLeftId('')
    setLastMenu('')
  }

  const changeMenus = async (value) => {
    console.log(value)
    setCompId(value)
    await dispatch(
      getMenuTreeList({
        co_id: value,
        page: 1,
        top: true,
      })
    )
    await dispatch(
      getMenuList({
        co_id: value,
        top: true,
      })
    )
  }
  const searchMenus = (val) => {
    console.log('123', val)
  }

  return (
    <div className={styles.root}>
      <div className="right_content">
        <Breadcrumb>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Item>菜单管理</Breadcrumb.Item>
        </Breadcrumb>
        <Layout className="layout-contanior">
          <Card className="scroll">
            <Sider>
              {getSuperGroup() === 'true' ? (
                <div label="企业/产品" name="co_id" className="seachCo">
                  <div style={{ width: 80 }}>企业/产品</div>
                  <Select
                    value={compId}
                    style={{ width: 'calc(100% - 40px)' }}
                    placeholder="请选择"
                    showSearch
                    placeholder="请选择企业/产品"
                    optionFilterProp="children"
                    onChange={change}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {compListAll.map((item) => (
                      <Select.Option value={item.co_id} key={item.co_id}>
                        {item.co_name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              ) : (
                ''
              )}
              <Tree onSelect={onSelect} treeData={menuTreeList} />
            </Sider>
          </Card>
          <Layout className="site-layout-background">
            <Content>
              <div className="content_card">
                <Card className="content_header_card">
                  <Form
                    autoComplete="off"
                    className="comment-seach"
                    ref={formRef}
                    onFinish={search}
                  >
                    <Form.Item
                      label="菜单"
                      className="comment-input"
                      name="fuzzy_name"
                    >
                      <Input placeholder="请输入菜单编号或者名称"></Input>
                    </Form.Item>
                    <Form.Item>
                      <Space size={[24]}>
                        <MyButton type="default" htmlType="submit">
                          查询
                        </MyButton>
                        <MyButton htmlType="reset">重置</MyButton>
                      </Space>
                    </Form.Item>
                  </Form>
                </Card>
                <div className="add-btn">
                  <MyButton type="default" onClick={showVisible}>
                    新增
                  </MyButton>
                </div>
                <div className="table_hei" ref={tableRef}>
                  <ConfigProvider locale={zhCH}>
                    <Table
                      scroll={{ y: size?.height - 95 + 'px' }}
                      size="small"
                      onChange={onChange}
                      rowKey={(i) => {
                        return i.menu_id
                      }}
                      rowSelection={{ ...rowSelection }}
                      columns={columns}
                      dataSource={menuAllList}
                      pagination={
                        paramsRef.current.isSearch ? paginations : false
                      }
                    />
                  </ConfigProvider>
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
      <Drawer
        width={624}
        destroyOnClose={true}
        size="large"
        maskClosable={false}
        title={menuInfo.menu_id ? '修改菜单' : '新增菜单'}
        placement="right"
        onClose={() => {
          setVisible(false)
          setMenuInfo({})
        }}
        visible={visible}
      >
        <Form
          autoComplete="off"
          initialValues={{
            open_mode: 'CURRENT_PAGE',
            co_id: compId,
          }}
          ref={addRef}
          onFinish={onFinish}
          validateTrigger={['onBlur', 'onChange']}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
        >
          <div className="form-btn">
            <div className="flex">
              {getSuperGroup() === 'true' ? (
                <Form.Item
                  label="企业/产品"
                  name="co_id"
                  className="w-50"
                  rules={rulesObj.current.co_id}
                >
                  <Select
                    disabled
                    onChange={changeMenus}
                    onSearch={searchMenus}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    placeholder="请选择企业/产品"
                  >
                    {compListAll.map((item) => (
                      <Select.Option value={item.co_id} key={item.co_id}>
                        {item.co_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}

              <Form.Item
                className="w-50"
                rules={rulesObj.current.menu_code}
                label="菜单编号"
                name="menu_code"
              >
                <Input maxLength={50} placeholder="不超过50个字"></Input>
              </Form.Item>
            </div>
            <Form.Item
              rules={rulesObj.current.menu_name}
              label="菜单名称"
              name="menu_name"
            >
              <Input maxLength={50} placeholder="不超过50个字"></Input>
            </Form.Item>
            <Form.Item
              label="菜单URL"
              name="menu_url"
              rules={rulesObj.current.menu_url}
            >
              <Input maxLength={500} placeholder="不超过500个字"></Input>
            </Form.Item>
            <Form.Item
              label="菜单描述"
              name="menu_desc"
              rules={rulesObj.current.menu_desc}
            >
              <Input.TextArea
                maxLength={500}
                showCount
                placeholder="请输入企业/产品描述,不超过500个字"
                style={{ height: '100px' }}
              ></Input.TextArea>
            </Form.Item>
            <Form.Item label="打开方式" name="open_mode">
              <Radio.Group value={radio}>
                <Radio value="CURRENT_PAGE">当前页面打开</Radio>
                <Radio value="NEW_PAGE">新打开标签页</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="上级菜单" name="parent_name">
              <TreeSelect
                disabled
                treeDefaultExpandAll
                key="co_id"
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={menuTreeList}
                placeholder="请选择菜单名称"
                onSelect={changeMenu}
              ></TreeSelect>
            </Form.Item>
            <Form.Item label="图标" name="icon">
              {!imgUrl ? (
                <a href="#" onClick={uploadIcon}>
                  上传图标
                </a>
              ) : (
                <div className="img">
                  <img style={{ width: 16, height: 16 }} src={imgUrl} alt="" />
                </div>
              )}
              {imgUrl && (
                <a href="#" onClick={uploadIcon}>
                  重新上传
                </a>
              )}

              <input
                ref={iptRef}
                type="file"
                style={{ display: 'none' }}
                onChange={onUploadInputFile}
              />
            </Form.Item>
          </div>
          <Form.Item>
            <div className="flex_end">
              <Space>
                <MyButton onClick={() => setVisible(false)}>取消</MyButton>
                <MyButton type="default" htmlType="submit">
                  {menuInfo.menu_id ? '修改' : '新增'}
                </MyButton>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
      {/* 删除菜单 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setDelModal(false)}
        title="删除菜单"
        visible={delModal}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setDelModal(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => delMenu(menuInfo.menu_id)}>
              删除
            </MyButton>
          </Space>,
        ]}
      >
        <p>确定要删除菜单“{menuInfo.menu_name}”及其下级菜单吗？</p>
      </Modal>
      {/* 移动弹窗 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setMoveModal(false)}
        title="移动菜单"
        visible={moveModal}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setMoveModal(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => changeMove(menuInfo)}>
              确定
            </MyButton>
          </Space>,
        ]}
      >
        移动到：
        <TreeSelect
          style={{ width: 238 }}
          treeDefaultExpandAll
          key="co_id"
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={menuTreeList}
          placeholder="请选择菜单"
          onSelect={changeMenu}
        ></TreeSelect>
      </Modal>
    </div>
  )
}
