import React from 'react'
import ReactDOM from 'react-dom'
// 通用的样式
import 'antd/dist/antd.css'
import 'element-theme-default'

import './index.scss'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
