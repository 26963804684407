import React, { useRef } from 'react'
import {
  Layout,
  Menu,
  Form,
  Popconfirm,
  message,
  Input,
  Modal,
  Button,
  Dropdown,
  Popover,
} from 'antd'
import styles from './index.module.scss'
import photo from '../../assets/u3.png'
import logo from '../../assets/u27.png'
import {
  useLocation,
  Switch,
  Route,
  Link,
  useHistory,
  Router,
} from 'react-router-dom'
import { createFromIconfontCN } from '@ant-design/icons'
import Home from './Home'
import IntellectWrite from './IntellectWrite'
import WriteManage from './WriteManage'
import { useDispatch, useSelector } from 'react-redux'
import {
  changePassword,
  getMenus,
  getUserInfo,
  logout,
} from '../../store/actions/login'
import CompManage from './SysManage/CompManage'
import routes from '../../routers/index'
import Usermanage from './SysManage/UserManage'
import { useState } from 'react'
import routers, { MyIcon } from '../../routers/index'
import PrivateRoute from '../../components/PrivateRoute'
import OrgManage from './SysManage/OrgManage'
import MenuManage from './SysManage/MenuManage'
import FucManage from './SysManage/FucManage'
import RoleManage from './SysManage/RoleManage'
import ConfigManage from './SysManage/ConfigManage'
import Help from '../Help'
import ServiceAgreement from '../ServiceAgreement'
import PrivacyAgreement from '../PrivacyAgreement'
import ContactInformation from '../ContactInformation'
import { useEffect } from 'react'
import {
  getCompany,
  removeCompany,
  removeSuperGroup,
  removeToken,
} from '../../utils/cookie'
import DataDictionary from './SysManage/DataDictionary'
import request from '../../utils/request'
import ProductConfgTemp from './SysManage/ProductConfgTemp'
import JournalManage from './SysManage/JournalManage'
const { Header, Content, Sider } = Layout

export default function MyLayout() {
  const [isModal, setIsModal] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const [ispopover, setIspopover] = useState(false)
  const [helpPopover, setHelpPopover] = useState(false)
  const { menus } = useSelector((state) => state.login)
  const location = useLocation()
  let pathname = location.pathname
  console.log(pathname)
  const dispatch = useDispatch()
  const history = useHistory()
  const changeForm = useRef({})
  useEffect(() => {
    dispatch(
      getMenus({
        co_id: getCompany(),
      })
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  useEffect(() => {
    if (localStorage.getItem('firstLogin')) {
      localStorage.removeItem('firstLogin')
      menus.map((item) => {
        if (item.children) {
          history.push(item.children[0]?.menu_url)
        } else {
          history.push(item.menu_url)
        }
      })
    }
  }, [menus])

  const userInfo = useSelector((state) => state.login.userInfo)
  const { user } = userInfo

  const confirm = () => {
    let redirect = ''
    request
      .post('sys/logout/', {
        redirect: window.location.origin + window.location.pathname,
      })
      .then((res) => {
        console.log(res.data.data.logout_url)

        if (res.data.data.status === 'redirect') {
          window.location.href = res.data.data.logout_url

          removeToken()
          removeSuperGroup()
          removeCompany()
        }
      })
    message.success('退出成功')
  }

  const showModal = (e) => {
    e.stopPropagation()
    setIsModal(true)
    setIspopover(false)
  }

  const changePass = async () => {
    changeForm.current.submit()

    const values = changeForm.current.getFieldsValue([
      'old_password',
      'new_password_1',
      'new_password_2',
    ])
    await dispatch(changePassword(values))
    message.success('修改密码成功', () => {
      setIsModal(false)
      dispatch(logout())
      history.replace('/home')
    })
  }
  const to = (item) => {
    console.log(item)
    if (item.menu_url && item.open_mode === 'CURRENT_PAGE') {
      history.push(item.menu_url)
      if (item.menu_url === pathname) {
        window.location.reload()
      }
    } else if (item.menu_url && item.open_mode === 'NEW_PAGE') {
      window.open(item.menu_url)
    } else if (item.menu_url === pathname) {
    }
  }
  const MyIcon = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_3250468_t51dp1wy2j.js',
  })

  const initMenu = (list, isChildren) => {
    list = list.map((item) => {
      if (item.children && item.children.length > 0) {
        return (
          <Menu.SubMenu
            // icon={item.icon}
            icon={
              isChildren ? null : item.icon ? (
                <img
                  alt=""
                  style={{
                    width: 16,
                    height: 16,
                  }}
                  src={item.icon}
                />
              ) : (
                <MyIcon type="icon-zhinengzhuanxie18x18" />
              )
            }
            title={item.menu_name}
            key={item.menu_url || item.menu_code}
          >
            {initMenu(item.children, true)}
          </Menu.SubMenu>
        )
      }

      return (
        <Menu.Item
          key={item.menu_url || item.menu_code}
          icon={
            isChildren ? null : item.icon ? (
              <img
                alt=""
                style={{
                  width: 16,
                  height: 16,
                }}
                src={item.icon}
              />
            ) : (
              <MyIcon type="icon-zhinengzhuanxie18x18" />
            )
          }
        >
          <a onClick={() => to(item)}>{item.menu_name}</a>
        </Menu.Item>
      )
    })
    return list
  }
  return (
    <div className={styles.root}>
      <Layout className="my-layout">
        <Sider
          trigger={null}
          width={216}
          style={{
            background: 'linear-gradient(223deg, #006bb3 0%, #15d2d6 100%)',
          }}
          className="site-layout-background"
          collapsible
          collapsed={collapsed}
        >
          <div className="logo">
            {!collapsed && <img src={logo} alt="" />}
            <span>
              {collapsed ? (
                <MyIcon
                  className="anticon-menu-fold"
                  type="icon-toggle-right1"
                  onClick={() => setCollapsed(!collapsed)}
                ></MyIcon>
              ) : (
                <MyIcon
                  className="anticon-menu-unfold "
                  type="icon-toggle-left2"
                  onClick={() => setCollapsed(!collapsed)}
                ></MyIcon>
              )}
            </span>
          </div>
          <Menu
            mode="inline"
            style={{ height: '100%', borderRight: 0 }}
            selectedKeys={[pathname]}
            defaultOpenKeys={['/home/sysmanage']}
          >
            {/* 遍历路由 */}
            {initMenu(menus)}
          </Menu>
        </Sider>

        <Layout>
          <Header>
            <span className="left-title">吾道科技管理系统</span>

            <>
              <div className="ant-header-right">
                <img
                  width={40}
                  height={40}
                  style={{ borderRadius: '50%' }}
                  src={photo}
                  alt=""
                />
                <span className="sys-pepo">{userInfo?.show_name}</span>
                <Popconfirm
                  placement="topRight"
                  title=" 你确定要退出吗？"
                  onConfirm={confirm}
                  okText="确定"
                  cancelText="取消"
                >
                  <a href="">退出</a>
                </Popconfirm>
                <span>
                  <MyIcon type="icon-a-guangbo1"></MyIcon>
                </span>
                <span className="setting">
                  <Popover
                    onVisibleChange={() => setIspopover(!ispopover)}
                    visible={ispopover}
                    title="设置"
                    trigger="click"
                    content={<a onClick={showModal}>修改密码</a>}
                  >
                    <MyIcon
                      type="icon-a-icon_shezhi1"
                      onClick={() => setIspopover(true)}
                    ></MyIcon>
                  </Popover>
                </span>
                <span>
                  <Popover
                    trigger="click"
                    content={
                      <div style={{ width: 60 }}>
                        <a
                          onClick={() => {
                            window.open(
                              'https://guodongbond.com/znsb/user-guide.php',
                              '_bank'
                            )
                          }}
                        >
                          帮助中心
                        </a>
                        <a
                          onClick={() => {
                            window.open('/legal-agreement/tos.html', '_bank')
                          }}
                        >
                          服务协议
                        </a>
                        <a
                          onClick={() => {
                            window.open(
                              '/legal-agreement/privacy.html',
                              '_bank'
                            )
                          }}
                        >
                          隐私协议
                        </a>
                        <a
                          onClick={() => {
                            window.open('/legal-agreement/about.html', '_bank')
                          }}
                        >
                          联系方式
                        </a>
                      </div>
                    }
                  >
                    <MyIcon
                      type="icon-a-icon_yiwenkongxin1"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setHelpPopover(true)
                      }}
                    ></MyIcon>
                  </Popover>
                </span>
              </div>
            </>
          </Header>

          <Content className="site-layout-background">
            <Switch>
              <PrivateRoute exact path="/home" component={Home}></PrivateRoute>
              <PrivateRoute
                path="/home/intellectwrite"
                component={IntellectWrite}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/writemanage"
                component={WriteManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/compmanage"
                component={CompManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/usermanage"
                component={Usermanage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/orgmanage"
                component={OrgManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/menumanage"
                component={MenuManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/fucmanage"
                component={FucManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/rolemanage"
                component={RoleManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/datadictionary"
                component={DataDictionary}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/configmanage"
                component={ConfigManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/productconfgtemp"
                component={ProductConfgTemp}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/journalmanage"
                component={JournalManage}
              ></PrivateRoute>
              <Route path="/help" component={Help}></Route>
              <Route
                path="/legal-agreement/tos.html"
                component={ServiceAgreement}
              ></Route>
              <Route
                path="/legal-agreement/privacy.html"
                component={PrivacyAgreement}
              ></Route>
              <Route
                path="/legal-agreement/about.html"
                component={ContactInformation}
              ></Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
      {/* 设置密码 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setIsModal(false)}
        onOk={changePass}
        title="修改密码"
        visible={isModal}
        getContainer={false}
        okText="修改"
        cancelText="取消"
        okType="primary"
      >
        <Form
          autoComplete="off"
          ref={changeForm}
          labelAlign="left"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            label="原密码"
            name="old_password"
            rules={[
              {
                required: true,
                message: '原密码不能为空',
              },
            ]}
          >
            <Input.Password></Input.Password>
          </Form.Item>
          <Form.Item
            label="新的密码"
            name="new_password_1"
            rules={[
              {
                required: true,
                message: '新密码不能为空',
              },
            ]}
          >
            <Input.Password></Input.Password>
          </Form.Item>
          <Form.Item
            label="确认新密码"
            name="new_password_2"
            rules={[
              {
                required: true,
                message: '确认密码不能为空',
              },
            ]}
          >
            <Input.Password></Input.Password>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
