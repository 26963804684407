import React, { useRef } from 'react'
import {
  Layout,
  Breadcrumb,
  Card,
  Form,
  Input,
  Button,
  Space,
  Table,
  Drawer,
  TreeSelect,
  message,
  Modal,
  ConfigProvider,
  Tooltip,
  Select,
} from 'antd'
import styles from './index.module.scss'
import { Tree } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  addOrg,
  delOrgs,
  editOrgs,
  getOrgAllList,
  getOrgInfo,
  getOrgList,
  getOrgTreeList,
  getTrigger,
  moveOrgs,
} from '../../../../store/actions/org'
import { useState } from 'react'
import zhCH from 'antd/lib/locale/zh_CN'
import { getCompany, getSuperGroup } from '../../../../utils/cookie'
import { getCompLists } from '../../../../store/actions/comp'
import { useSize, useGetState } from 'ahooks'
import MyButton from '../../../../components/MyButton'
const { Sider, Content } = Layout
export default function OrgManage() {
  // console.log('123')
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [lastOrg, setLastOrg] = useState('')
  const [orgInfo, setOrgInfo, getOrgInfo] = useGetState({})
  // const [coId, setCoId, getOrgId] = useGetState('')

  const [delModal, setDelModal] = useState(false)
  const [moveModal, setMoveModal] = useState(false)
  const { orgTreeList } = useSelector((state) => state.org)
  const { total, orgAllList } = useSelector((state) => state.org)
  const { compListAll } = useSelector((state) => state.comp)
  const [orgId, setOrgId, getOrgId] = useGetState('')
  console.log(orgId)

  const [leftId, setLeftId] = useState('')
  const formRef = useRef()
  const tableRef = useRef(null)
  const size = useSize(tableRef)
  const paramsRef = useRef({
    page: 1,
    page_size: 10,
    isSearch: false,
  })
  const addRef = useRef()
  const selectRef = useRef()

  const rulesObj = useRef({
    co_id: [
      {
        required: true,
        message: '请选择企业/产品',
      },
    ],
    org_code: [
      {
        required: true,
        message: '部门编号不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
      {
        validator(_, value) {
          return dispatch(
            getTrigger(
              {
                pk: getOrgInfo().org_id ? getOrgInfo().org_id : null,
                co_id: getOrgId() ? getOrgId() : null,

                field: 'code',
                value,
              },
              false
            )
          ).then(({ data }) => {
            if (!data.data) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('部门编号已存在'))
          })
        },
        validateTrigger: 'onBlur',
      },
    ],
    org_name: [
      {
        required: true,
        message: '部门名称不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
      {
        validator(_, value) {
          return dispatch(
            getTrigger(
              {
                pk: getOrgInfo().org_id ? getOrgInfo().org_id : null,
                co_id: getOrgId() ? getOrgId() : null,

                field: 'name',
                value,
              },
              false
            )
          ).then(({ data }) => {
            if (!data.data) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('部门名称已存在'))
          })
        },
        validateTrigger: 'onBlur',
      },
    ],
    org_desc: [
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
  })

  // 树形数据
  useEffect(() => {
    dispatch(
      getOrgTreeList({
        co_id: getCompany(),
      })
    )
    getOrgComment()
  }, [])

  useEffect(() => {
    if (compListAll.length > 0) {
      setOrgId(getCompany())
    }
  }, [compListAll])

  //获取企业/产品列表
  useEffect(() => {
    getSuperGroup() === 'true' &&
      dispatch(
        getCompLists({
          is_page: true,
        })
      )
  }, [dispatch])

  // 数据回显
  useEffect(() => {
    const values = { ...orgInfo }
    setTimeout(() => {
      addRef.current?.setFieldsValue({
        ...values,
        parent: values.parent_name,
        parent_name: leftId === undefined ? '全部' : leftId,
        co_id: orgInfo.co_id,
      })
    }, 0)
  }, [orgInfo])

  // 表格数据
  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])

  const columns =
    getSuperGroup() === 'true'
      ? [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            ellipsis: {
              showTitle: false,
            },
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '企业/产品',
            dataIndex: 'user',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={record.co_name}>
                {record.co_name}
              </Tooltip>
            ),
          },
          {
            title: '部门编号',
            dataIndex: 'org_code',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '部门名称',
            dataIndex: 'org_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '上级部门',
            dataIndex: 'parent_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '部门描述',
            dataIndex: 'org_desc',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip
                overlayStyle={{ maxWidth: 560 }}
                placement="leftTop"
                title={text}
              >
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'orgInfo',
            width: 230,
            fixed: 'right',
            render: (org, index, i) => {
              return (
                <>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => {
                      editOrg(org)
                    }}
                  >
                    修改
                  </Button>

                  <Button
                    onClick={() => {
                      setMoveModal(true)
                      setMoveValue(null)
                      setOrgInfo(org)
                      setLastOrg('')
                    }}
                    type="link"
                    disabled={
                      orgAllList.length === i || paramsRef.current.isSearch
                    }
                    className={{
                      edit:
                        orgAllList.length !== i && !paramsRef.current.isSearch,
                    }}
                  >
                    移动
                  </Button>
                  <Button
                    disabled={i === 0 || paramsRef.current.isSearch}
                    type="link"
                    className={{ edit: i !== 0 && !paramsRef.current.isSearch }}
                    onClick={() => {
                      changeUp(org.org_id, org.parent)
                    }}
                  >
                    上移
                  </Button>
                  <Button
                    onClick={() => {
                      changeDown(org.org_id, org.parent)
                    }}
                    disabled={
                      orgAllList.length - 1 === i || paramsRef.current.isSearch
                    }
                    type="link"
                    className={{
                      edit:
                        orgAllList.length - 1 !== i &&
                        !paramsRef.current.isSearch,
                    }}
                  >
                    下移
                  </Button>
                  <Button
                    type="link"
                    className="del"
                    onClick={() => showDelModal(org)}
                  >
                    删除
                  </Button>
                </>
              )
            },
          },
        ]
      : [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },

          {
            title: '部门编号',
            dataIndex: 'org_code',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '部门名称',
            dataIndex: 'org_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '上级部门',
            dataIndex: 'parent_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '部门描述',
            dataIndex: 'org_desc',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip
                overlayStyle={{ maxWidth: 560 }}
                placement="leftTop"
                title={text}
              >
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'orgInfo',
            width: 230,
            fixed: 'right',
            render: (org, index, i) => {
              return (
                <>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => editOrg(org)}
                  >
                    修改
                  </Button>

                  <Button
                    onClick={() => {
                      setMoveModal(true)
                      setMoveValue(null)
                      setOrgInfo(org)
                      setLastOrg('')
                    }}
                    type="link"
                    disabled={
                      orgAllList.length === i || paramsRef.current.isSearch
                    }
                    className={{
                      edit:
                        orgAllList.length !== i && !paramsRef.current.isSearch,
                    }}
                  >
                    移动
                  </Button>
                  <Button
                    disabled={i === 0 || paramsRef.current.isSearch}
                    type="link"
                    className={{ edit: i !== 0 && !paramsRef.current.isSearch }}
                    onClick={() => {
                      changeUp(org.org_id, org.parent)
                    }}
                  >
                    上移
                  </Button>
                  <Button
                    onClick={() => {
                      changeDown(org.org_id, org.parent)
                    }}
                    disabled={
                      orgAllList.length - 1 === i || paramsRef.current.isSearch
                    }
                    type="link"
                    className={{
                      edit:
                        orgAllList.length - 1 !== i &&
                        !paramsRef.current.isSearch,
                    }}
                  >
                    下移
                  </Button>
                  <Button
                    type="link"
                    className="del"
                    onClick={() => showDelModal(org)}
                  >
                    删除
                  </Button>
                </>
              )
            },
          },
        ]

  const getOrgComment = (search) => {
    let params = {}
    if (!paramsRef.current.isSearch) {
      params.co_id = orgId
      params.parent = paramsRef.current.org_id
      params.top = params.parent ? null : true
    } else {
      params.fuzzy_name = formRef.current.getFieldValue('fuzzy_name')
    }
    params.is_page = true
    params.page = paramsRef.current.page
    params.pageSize = paramsRef.current.page_size
    dispatch(getOrgAllList(params))
  }

  // 左侧树形数据右侧渲染
  const onSelect = (selectedKeys, info) => {
    setLeftId(info.node.org_name)
    setLastOrg(selectedKeys[0] === '全部' ? '' : selectedKeys[0])
    paramsRef.current = info.node
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
    paramsRef.current.isSearch = false

    getOrgComment()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys)
    },
  }

  // 新增弹窗
  const showVisible = () => {
    let org = {
      co_id: orgId,
      parent_name: leftId === undefined ? '全部' : leftId,
    }
    setOrgInfo(org)
    setVisible(true)
  }
  // 修改弹窗
  const editOrg = (org) => {
    org = JSON.parse(JSON.stringify(org))
    setOrgInfo(org)
    setVisible(true)
  }
  // 删除弹窗
  const showDelModal = (org) => {
    setOrgInfo(org)
    setDelModal(true)
  }
  // 新增部门
  const onFinish = async (values) => {
    if (orgInfo.org_id) {
      values.parent = orgInfo.parent
      await dispatch(editOrgs({ ...values, id: orgInfo.org_id }))
      setOrgInfo({})
    } else {
      values.parent = lastOrg
      // setLastOrg('')
      await dispatch(addOrg(values))
    }
    message.success(orgInfo.org_id ? '修改部门成功' : '新增部门成功')
    paramsRef.current.page = 1
    setVisible(false)
    getOrgComment()
    await dispatch(
      getOrgTreeList({
        co_id: orgId,
        top: true,
      })
    )
  }
  const [moveValue, setMoveValue] = useState(null)
  // 选择部门
  const changeOrg = (value, node) => {
    setMoveValue(value)
    setLastOrg(node.org_id)
  }

  // 搜索所有部门
  const search = async (values) => {
    paramsRef.current.isSearch = true
    paramsRef.current.page = 1

    await getOrgComment()
  }

  // 分页处理
  const onChange = (page, pageSize) => {
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    getOrgComment()
  }

  // 删除部门
  const delOrg = async (id) => {
    await dispatch(delOrgs(id))
    message.success('删除部门成功')
    paramsRef.current.page = 1
    setDelModal(false)
    getOrgComment()
    dispatch(
      getOrgTreeList({
        co_id: orgId,
        top: true,
      })
    )
  }

  //上移
  const changeUp = async (id, parent) => {
    await dispatch(
      moveOrgs({
        pk: id,
        action: 'up',
        parent: parent,
      })
    )
    await dispatch(
      getOrgTreeList({
        co_id: compId,
        top: true,
      })
    )
    message.success('上移成功')
    getOrgComment()
  }
  // 下移
  const changeDown = async (id, parent) => {
    await dispatch(
      moveOrgs({
        pk: id,
        action: 'down',
        parent: parent,
      })
    )
    await dispatch(
      getOrgTreeList({
        co_id: compId,
        top: true,
      })
    )
    message.success('下移成功')
    getOrgComment()
  }

  // 移动弹窗
  const changeMove = async (params) => {
    if (!moveValue) {
      return message.error('请选择部门')
    }
    setOrgInfo(params)
    await dispatch(
      moveOrgs({
        pk: params.org_id,
        action: 'move',
        parent: lastOrg ? lastOrg : null,
      })
    )
    message.success('移动成功')
    setMoveModal(false)
    getOrgComment()
    await dispatch(
      getOrgTreeList({
        co_id: orgId,
        top: true,
      })
    )
  }

  const paginations = {
    defaultCurrent: 1,
    current: paramsRef.current.page,
    defaultPageSize: 10,
    total: total,
    showTotal: (total) => {
      return `共${total}条`
    },
  }

  const onSearch = (val) => {
    console.log('123', val)
  }
  const [compId, setCompId] = useState(getCompany())
  const change = async (value) => {
    console.log(value)
    setOrgId(value)
    setCompId(value)
    await dispatch(
      getOrgTreeList({
        co_id: value,
        top: true,
      })
    )
    await dispatch(
      getOrgAllList({
        co_id: value,
        top: true,
        is_page: true,
      })
    )
    setLeftId('')
    setLastOrg('')
  }
  const changeComps = async (value) => {
    setOrgId(value)
    await dispatch(
      getOrgTreeList({
        co_id: value,
        page: 1,
        top: true,
      })
    )
    await dispatch(
      getOrgAllList({
        co_id: value,
        top: true,
      })
    )
  }
  const searchComps = (val) => {
    console.log('123', val)
  }

  return (
    <div className={styles.root}>
      <div className="right_content">
        <Breadcrumb>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Item>组织管理</Breadcrumb.Item>
        </Breadcrumb>
        <Layout className="layout-contanior">
          <Card className="scroll">
            <Sider>
              {getSuperGroup() === 'true' ? (
                <div label="企业/产品" name="co_id" className="seachCo">
                  <div style={{ width: 80 }}>企业/产品</div>
                  <Select
                    style={{ width: 'calc(100% - 40px)' }}
                    value={orgId}
                    // defaultValue={orgId}
                    showSearch
                    placeholder="请选择企业/产品"
                    optionFilterProp="children"
                    onChange={change}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {compListAll.map((item) => (
                      <Select.Option value={item.co_id} key={item.co_id}>
                        {item.co_name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              ) : (
                ''
              )}

              <Tree onSelect={onSelect} treeData={orgTreeList} />
            </Sider>
          </Card>
          <Layout className="site-layout-background">
            <Content style={{ height: '100%' }}>
              <div className="content_card">
                <Card className="content_header_card">
                  <Form
                    autoComplete="off"
                    className="comment-seach"
                    ref={formRef}
                    onFinish={search}
                  >
                    <Form.Item
                      label="组织"
                      className="comment-input"
                      name="fuzzy_name"
                    >
                      <Input placeholder="请输入部门编号或者名称"></Input>
                    </Form.Item>
                    <Form.Item>
                      <Space size={[24]}>
                        <MyButton
                          type="default"
                          htmlType="submit"
                          onClick={search}
                        >
                          查询
                        </MyButton>
                        <MyButton htmlType="reset">重置</MyButton>
                      </Space>
                    </Form.Item>
                  </Form>
                </Card>
                <div className="add-btn">
                  <MyButton type="default" onClick={showVisible}>
                    新增
                  </MyButton>
                </div>
                <div className="table_hei" ref={tableRef}>
                  <ConfigProvider locale={zhCH}>
                    <Table
                      scroll={{ y: size?.height - 95 + 'px' }}
                      size="small"
                      onChange={onChange}
                      rowKey={(i) => {
                        return i.org_id
                      }}
                      rowSelection={{ ...rowSelection }}
                      columns={columns}
                      dataSource={orgAllList}
                      pagination={
                        paramsRef.current.isSearch ? paginations : false
                      }
                    />
                  </ConfigProvider>
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
      <Drawer
        width={624}
        destroyOnClose={true}
        size="large"
        maskClosable={false}
        title={orgInfo.org_id ? '修改部门' : '新增部门'}
        placement="right"
        onClose={() => {
          setVisible(false)
          setOrgInfo({})
        }}
        visible={visible}
      >
        <Form
          autoComplete="off"
          ref={addRef}
          onFinish={onFinish}
          validateTrigger={['onBlur', 'onChange']}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
        >
          <div className="form-btn">
            <div className="flex">
              {getSuperGroup() === 'true' ? (
                <Form.Item
                  label="企业/产品"
                  name="co_id"
                  className="w-50"
                  rules={rulesObj.current.co_id}
                >
                  <Select
                    disabled
                    showSearch
                    placeholder="请选择企业/产品"
                    onChange={changeComps}
                    onSearch={searchComps}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {compListAll.map((item) => (
                      <Select.Option value={item.co_id} key={item.co_id}>
                        {item.co_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}

              <Form.Item
                className="w-50"
                rules={rulesObj.current.org_code}
                label="部门编号"
                name="org_code"
              >
                <Input maxLength={50} placeholder="不超过50个字"></Input>
              </Form.Item>
            </div>
            <Form.Item
              rules={rulesObj.current.org_name}
              label="部门名称"
              name="org_name"
            >
              <Input maxLength={50} placeholder="不超过50个字"></Input>
            </Form.Item>
            <Form.Item
              label="部门描述"
              name="org_desc"
              rules={rulesObj.current.org_desc}
            >
              <Input.TextArea
                style={{ height: '100px' }}
                maxLength={500}
                placeholder="不超过500个字"
              ></Input.TextArea>
            </Form.Item>
            <Form.Item label="上级部门" name="parent_name">
              <TreeSelect
                disabled
                treeDefaultExpandAll
                key="org_id"
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={orgTreeList}
                placeholder="请选择部门名称"
                onSelect={changeOrg}
              ></TreeSelect>
            </Form.Item>
          </div>
          <Form.Item>
            <div className="flex_end">
              <Space>
                <MyButton onClick={() => setVisible(false)}>取消</MyButton>
                <MyButton type="default" htmlType="submit">
                  {orgInfo.org_id ? '修改' : '新增'}
                </MyButton>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
      {/* 删除部门 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setDelModal(false)}
        title="删除部门"
        visible={delModal}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setDelModal(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => delOrg(orgInfo.org_id)}>
              删除
            </MyButton>
          </Space>,
        ]}
      >
        <p>确定要删除部门“{orgInfo.org_name}”及其下级部门吗？</p>
      </Modal>
      {/* 移动弹窗 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setMoveModal(false)}
        title="移动部门"
        visible={moveModal}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setMoveModal(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => changeMove(orgInfo)}>
              确定
            </MyButton>
          </Space>,
        ]}
      >
        移动到：
        <TreeSelect
          ref={selectRef}
          style={{ width: 238 }}
          treeDefaultExpandAll
          key="org_id"
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={orgTreeList}
          placeholder="请选择部门"
          onSelect={changeOrg}
        ></TreeSelect>
      </Modal>
    </div>
  )
}
