import { message } from 'antd'
import request from 'axios'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { login } from '../../store/actions/login'
import { LOGIN } from '../../store/constants'
import { setCompany, setSuperGroup, setToken } from '../../utils/cookie'

export default function Login() {
  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(login()).then((res) => {
      console.log(res)
      if (res) {
        console.log('home')
        message.success('登录成功')
        history.push('/home')
        localStorage.setItem('firstLogin', true)
      }
    })
  }, [])
  return <div></div>
}
