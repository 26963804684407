import { getSuperGroup, getToken } from '../../utils/cookie'
import {
  GETLOGINLOG,
  GETMENUS,
  GETOPTLOG,
  LOGIN,
  LOGOUT,
  USERINFO,
} from '../constants'

const init = {
  token: getToken(),
  company: '',
  super_group: getSuperGroup(),
  menus: [],
  userInfo: {},
  loginLog: [],
  loginTotal: 0,
  optLog: [],
  optTotla: 0,
}
export default function login(state = init, action) {
  if (action.type === LOGIN) {
    return {
      ...state,
      token: action.payload.token,
      company: action.payload.company,
      super_group: action.payload.super_group,
    }
  }
  if (action.type === GETMENUS) {
    return {
      ...state,
      menus: action.payload,
    }
  }
  if (action.type === LOGOUT) {
    return {
      ...state,
      token: '',
      super_group: null,
      company: '',
    }
  }
  if (action.type === USERINFO) {
    return {
      ...state,
      userInfo: action.payload,
    }
  }
  if (action.type === GETLOGINLOG) {
    return {
      ...state,
      loginLog: action.payload.data,
      loginTotal: action.payload.total,
    }
  }
  if (action.type === GETOPTLOG) {
    return {
      ...state,
      optLog: action.payload.data,
      optTotla: action.payload.total,
    }
  }
  return state
}
