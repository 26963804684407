export const LOGIN = 'LOGIN'
export const LOGOUT = 'login/logout'
export const GETMENUS = 'login/getmenus'
export const USERINFO = 'login/getuserinfo'
export const GETLOGINLOG = 'login/getloginlog'
export const GETOPTLOG = 'login/getoptlog'
export const REGISTERUSER = 'registerUser'

export const GETCOMPLIST = 'comp/getcomplist'
export const GETCOMPLISTS = 'comp/getcomplists'
export const GETCOMPLISTALL = 'comp/getcomplistall'
export const GETCOMPMODE = 'comp/getcompmode'
export const ADDCOMPS = 'comp/addcomps'
export const GETCOMPINFO = 'comp/getcompinfo'
export const GETDICITEM = 'comp/getdicitem'
export const GETCOMPSTATUS = 'comp/getcompstatus'

export const GETUSERLIST = 'user/getuserlist'
export const GETUSERINFO = 'user/getuserinfo'

export const GETORGLIST = 'org/getorglist'
export const GETORGALLLIST = 'org/getorgalllist'
export const GETORGTREELIST = 'org/getorgtreeliist'
export const GETORGINFO = 'ogr/getorginfo'

export const GETMENULIST = 'menu/getmenulist'
export const GETMENUTREELIST = '/menu/getmenutreelist'

export const GETFUCLIST = 'fuc/getfuclist'
export const GETFUCTREELIST = 'fuc/getfuctreelist'

export const GETPERMISSIONLIST = 'permission/getpermissionlist'
export const GETPERMISSIONLISTS = 'permission/getpermissionlists'

export const GETPERMISSIONUSERLIST = 'permission/getpermissionuserlist'
export const GETPERMISSIONUSERLISTS = 'permission/getpermissionuserlists'
export const GETPERMISSIONORGLIST = 'permission/getpermissionorglist'
export const GETPERMISSIONORGLISTS = 'permission/getpermissionorglists'
export const GETPERMISSIONMENULIST = 'permission/getpermissionmenulist'
export const GETPERMISSIONFUCLIST = 'permission/getpermissionfuclist'
export const GETPERMISSIONMENUTREELIST = 'permission/getpermissionmenutreelist'
export const GETPERMISSIONFUNCTREELIST = 'permission/getpermissionfunctreelist'

export const GETDICTIONARY = 'dictionary/getdirtionarylist'
export const GETOPTTYPE = 'dictionary/getopttype'
export const GETLOGINSTATUS = 'dictionary/getloginstatus'

export const GETCONFIGMANAGELIST = 'cfg/getconfigmanagelist'

export const GETPRODUCTCFGTEMP = 'productcfgtemp/getproductcfgtemp'
