import { combineReducers } from 'redux'
import login from './login'
import comp from './comp'
import user from './user'
import org from './org'
import menu from './menu'
import fuc from './fuc'
import permission from './permission'
import dictionary from './dictionary'
import cfg from './cfg'
import productCfg from './productCfg'
const rootReducer = combineReducers({
  login,
  comp,
  user,
  org,
  menu,
  fuc,
  permission,
  dictionary,
  cfg,
  productCfg,
})

export default rootReducer
